import { logout } from './assets/js/logout';
import { useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

function useIdleTimer(timeout = 300 * 1000) {
    
    const navigate = useNavigate();
    const timerRef = useRef(null);

    const logoutHandle = async () => {
        const response = await logout();
        const toastId = toast.loading("Signing off...");
        setTimeout(async () => {
            toast.remove(toastId);
            if (response.status) { navigate("/"); }
        }, 800);
    };

    const resetTimer = useCallback(() => {
        if (timerRef.current) { clearTimeout(timerRef.current); }
        timerRef.current = setTimeout(() => { logoutHandle(); }, timeout);
    }, [logoutHandle, timeout]);

    useEffect(() => {
        resetTimer();
        const events = ['mousemove', 'keydown', 'click', 'scroll'];
        events.forEach(event => { window.addEventListener(event, resetTimer); });
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') { resetTimer(); } 
            else { if (timerRef.current) { clearTimeout(timerRef.current); } }
        };
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            if (timerRef.current) { clearTimeout(timerRef.current); }
            events.forEach(event => { window.removeEventListener(event, resetTimer); });
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [resetTimer, logoutHandle]);

    return null;
}

export default useIdleTimer;