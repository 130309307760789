import axios from 'axios';
import React, { useState } from 'react'
import toast from 'react-hot-toast';
import OtpInput from 'react-otp-input';
import { makeUrl } from '../../../assets/js/makeUrl';
import { encryptStorage } from '../../../assets/js/encryptStorage';
import { addHours, currency_list } from '../../../assets/js/helper';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import verificationImage from '../../../assets/verification.png'
import logo from '../../../assets/logo.png'
import loginBg from '../../../assets/loginImg.png'

import { IoChevronBackOutline } from "react-icons/io5";


const Verification = () => {
    const [otp, setOtp] = useState('');
    let userId = localStorage.getItem("uid")
    const navigate = useNavigate();

    const handleVerification = async () => {
        let loader = toast.loading("Processing Request")
        try {
            let url = makeUrl("users", "verify-otp");
            let response = await axios.post(url, { userId, otp })
            if (response) {
                toast.dismiss(loader)
                toast.success("OTP verified")
                localStorage.setItem("platform", response.data?.data?.user_type == "qb" ? "quickbook" : "xero")
                let token = response?.data?.token;
                let user = JSON.stringify(response?.data?.data);
                let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                encryptStorage.setItem("token", token);
                encryptStorage.setItem("user", user);
                encryptStorage.setItem("uid", response.data.data.id);
                encryptStorage.setItem("username", response.data.data.first_name);
                encryptStorage.setItem("company-id", response.data.data.company_id);
                encryptStorage.setItem("company-type", response.data.data.user_type);
                encryptStorage.setItem("user-type", response.data.data.role_id === 1 ? "admin" : "user");
                encryptStorage.setItem("expired-at", moment(addHours(3)).unix());
                encryptStorage.setItem("timezone", timezone);
                encryptStorage.setItem("company-name", response.data.company_data.company_name);
                encryptStorage.setItem("currency-code", response.data.company_data.currency);
                encryptStorage.setItem("current-page", "dashboard");
                if (response.data.company_data.currency !== null) {
                    let currency = currency_list.filter((el) => el.name === response.data.company_data.currency);
                    encryptStorage.setItem("currency-symbol", currency[0].code);
                }
                navigate("/dashboard");
            }
        }
        catch (error) {
            toast.dismiss(loader)
            console.log(error, 'error')
            toast.error(error?.response?.data?.msg)
        }
    }

    const handleResendOtp = async () => {
        let loader = toast.loading("Processing Request")
        try {
            let url = makeUrl("users", "resend-otp");
            let response = await axios.post(url, { userId })
            if (response.data) {
                toast.dismiss(loader)
                toast.success(response.data?.msg)
            }

        }
        catch (error) {
            toast.dismiss(loader)
            toast.error("Something went wrong")
        }
    }

    return (


        <div className='w-screen h-screen bg-[#fff] font-Inter p-5 overflow-y-auto'>

            <div className='h-[10vh] flex items-center'>
                <img src={logo} alt="" className='h-[3rem] cursor-pointer' onClick={() => navigate("/")} />
            </div>


            <div className='flex justify-evenly items-center lg:h-[80vh] flex-wrap'>

                <div className='mt-6 md:order-1 order-2 sm:flex-none flex-1'>

                    <Link to={"/"} className='flex items-center gap-x-1 cursor-pointer'>
                        <IoChevronBackOutline className='text-[#848484]' />
                        <p className='text-sm text-[#848484]'>Back to login</p>
                    </Link>
                    <h1 className='mt-3 text-4xl'>Verify code</h1>
                    <p className='mt-2 text-sm text-[#313131]'>An authentication code has been sent to your email.</p>

                    <h1 className='mt-4 text-base text-[#313131]'>Enter Code</h1>
                    <OtpInput inputStyle={{ width: "3rem", height: "3rem", borderRadius: "10px", border: "1px solid lightgray" }} containerStyle={{ marginTop: "1rem", display: "flex", alignItems: "center", gap: "1rem" }} value={otp} onChange={setOtp} numInputs={4} renderSeparator={<span>-</span>} renderInput={(props) => <input {...props} />} />
                    <p onClick={handleResendOtp} className='mt-6 text-sm'>Didn’t receive a code? <span className='text-[#41CCAD] cursor-pointer font-semibold'>Resend</span></p>
                    <button onClick={handleVerification} className='bg-[#41CCAD] text-white h-[2.8rem] rounded-md w-[100%] mt-5 font-Inter font-semibold text-lg'>Verify</button>

                </div>
                
                <div className='mt-6 md:order-2 order-1'>
                    <img src={loginBg} alt="" className='sm:h-[50vh] sm:min-w-[20rem]' />
                </div>

            </div>


        </div>

    )
}

export default Verification