import config from "../../configs/config";
import axios from "axios";
import { encryptStorage } from "./encryptStorage";
//____Function to make url by combining base url from config file, api type (node) and endpoint (node)___//
export const makeUrl = (prefix, endPoint) => {
  let url = `${config.base_url}/${prefix}/${endPoint}`;
  return url;
};

// post request helper function for before auth
const postWithoutHeaders = async (makeUrl, body) => {
  try {
    const res = await axios.post(makeUrl, body);
    return { error: false, res };
  } catch (error) {
    console.log(`error in postWithoutHeaders  ${makeUrl} ${error.message}`);

    return { error };
  }
};

// post request helper function for after auth
const post = async (
  endPoint,
  body,
  headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${encryptStorage.getItem("token")}`,
  }
) => {
  try {
    const res = await axios.post(makeUrl, body, headers);
    return { error: false, res };
  } catch (error) {
    console.log(`error in post function  ${makeUrl} ${error.message}`);

    return { error };
  }
};
const getWithoutHeaders = async (makeUrl) => {
  try {
    const res = await axios.get(makeUrl);
    return { error: false, res };
  } catch (error) {
    console.log(`error in getWithoutHeaders  ${makeUrl} ${error.message}`);
    return { error };
  }
};
const get = async (
  makeUrl,
  body,
  headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${encryptStorage.getItem("token")}`,
  }
) => {
  try {
    const res = await axios.get(makeUrl, body, headers);
    return { error: false, res };
  } catch (error) {
    console.log(`error in get function  ${makeUrl} ${error.message}`);

    return { error };
  }
};

const uploadImage = async (file) => {
  const url = `https://api.cloudinary.com/v1/upload`;
  const formData = new FormData();
  formData.append("file", file);
  formData.append("upload_preset", "your_preset_name"); // Replace with your Cloudinary preset

  try {
    const response = await axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data.secure_url;
  } catch (error) {
    console.error("Error uploading image:", error);
    return null; // Or handle the error differently
  }
};
const uploadMultipleImages = async (files) => {
  const promises = [];
  const urls = [];

  for (const file of files) {
    promises.push(uploadImage(file));
  }

  try {
    urls = await Promise.all(promises);
    return urls;
  } catch (error) {
    console.error("Error uploading images:", error);
    return []; // Or handle the error differently
  }
};

export {
  postWithoutHeaders,
  post,
  getWithoutHeaders,
  get,
  uploadImage,
  uploadMultipleImages,
};
